import { CheckCircleOutlined, ClockCircleOutlined, DeleteOutlined, ExclamationCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import { arrayOf, func, shape, string } from 'prop-types';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

// Internal components and hooks
import { SosSection } from './components';
import { donatePopupFields, SosItem } from '@/components/atoms';
import { PageError, PageHeader, PageLoading } from '@/components/organisms';
import { Layout } from '@/components/templates';
import { SosStatus, useSosNewContainerCheckoutSosMutation } from '@/graphql';

// Assets
import SosIcon from '@/assets/icons/sos.png';

const SosMySosLayout = ({ children, onBack }) => (
    <>
        <PageHeader icon={{ src: SosIcon }} onBack={onBack} title="My SOS" />
        <Layout>{children}</Layout>
    </>
);

const SosMySosData = ({ data, onBack, onDelete, onPaid }) => {
    const { search } = useLocation();
    const history = useHistory();

    useEffect(() => {
        const urlParams = new URLSearchParams(search);
        const params = {};
        for (const [key, value] of urlParams.entries()) {
            params[key] = value;
        }
        if (params.type === 'success') {
            saveSession(params);
        }
        if (params.type === 'cancel') {
            Modal.warning({
                title: 'You payment is pending',
                content: 'You can pay again from my items',
            });
        }
    }, [search]);

    const saveSession = async (params) => {
        try {
            await onPaid({
                id: params.id,
                session: params.session,
                price: Number(params.price * 100),
                session_id: params.session_id,
            });

            Modal.success({
                title: 'Transaction completed successfully',
                content: 'Thank you for your payment',
                onOk: () => {
                    history.replace('/sos/my-sos');
                    Modal.confirm({
                        ...donatePopupFields,
                    });
                },
            });
        } catch (e) {
            console.error(e);
            history.replace('/sos/my-sos');
        }
    };

    const [checkoutSos] = useSosNewContainerCheckoutSosMutation({
        onError: (error) => message.error(error.message),
    });
    const DeleteButton = ({ sos }) => (
        <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => {
                Modal.confirm({
                    title: 'Confirm',
                    icon: <ExclamationCircleOutlined />,
                    content: (
                        <>
                            Do you want to delete this SOS request?
                            <div>
                                "<i>{sos.message}</i>"
                            </div>
                        </>
                    ),
                    cancelButtonProps: {
                        danger: true,
                    },
                    onOk() {
                        onDelete(sos.id);
                    },
                    onCancel() {},
                });
            }}
            type="link"
        >
            Delete
        </Button>
    );

    const PayNowButton = ({ sos }) => {
        return (
            <Button
                className="payNowBtn"
                onClick={async () => {
                    const checkoutResponse = await checkoutSos({
                        variables: {
                            id: sos.id,
                        },
                    });
                    if (checkoutResponse && checkoutResponse.data && checkoutResponse.data.checkoutSos) {
                        window.open(checkoutResponse.data.checkoutSos);
                    }
                }}
                type="link"
            >
                Pay Now
            </Button>
        );
    };

    return (
        <SosMySosLayout onBack={onBack}>
            <SosSection data={data.me.mySos.filter((sos) => sos.status === SosStatus.Pending)} emptyText="You Have No SOS Awaiting Review" icon={<ClockCircleOutlined style={{ color: '#7fc1c6' }} />} renderItem={(sos) => <SosItem {...sos} actions={[!sos.isItemPaid ? <PayNowButton sos={sos} /> : null, <DeleteButton sos={sos} />]} />} title="Awaiting Review" />

            <SosSection
                data={data.me.mySos.filter((sos) => sos.status === SosStatus.Rejected)}
                emptyText="You Have No SOS That Require Attention"
                icon={<WarningOutlined style={{ color: '#f97f48' }} />}
                renderItem={(sos) => (
                    <SosItem
                        {...sos}
                        actions={[
                            <Link to={`/sos/${sos.id}/edit`}>
                                <Button type="link">Edit</Button>
                            </Link>,
                            <DeleteButton sos={sos} />,
                        ]}
                    />
                )}
                title="Attention Required"
            />

            <SosSection data={data.me.mySos.filter((sos) => sos.status === SosStatus.Approved)} emptyText="You Have Not Sent Any SOS" icon={<CheckCircleOutlined style={{ color: '#adc965' }} />} renderItem={(sos) => <SosItem {...sos} />} title="Sent SOS" />
        </SosMySosLayout>
    );
};

SosMySosData.propTypes = {
    data: shape({
        me: shape({
            id: string.isRequired,
            sos: arrayOf(
                shape({
                    id: string.isRequired,
                })
            ),
        }),
    }),
    onBack: func.isRequired,
    onDelete: func.isRequired,
};

const SosMySosView = {
    Error: ({ onBack, ...props }) => (
        <SosMySosLayout onBack={onBack}>
            <PageError {...props} />
        </SosMySosLayout>
    ),
    Data: SosMySosData,
    Loading: ({ onBack }) => (
        <SosMySosLayout onBack={onBack}>
            <PageLoading />
        </SosMySosLayout>
    ),
};

export { SosMySosView };
